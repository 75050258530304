import Footer from "../components/Footer";
import "../user/profile.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
//import MoveUpIcon from "@mui/icons-material/MoveUp";
import AddCardOutlinedIcon from "@mui/icons-material/AddCardOutlined";
import PriceChangeOutlinedIcon from "@mui/icons-material/PriceChangeOutlined";
//import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import LockPersonOutlinedIcon from "@mui/icons-material/LockPersonOutlined";
import LockResetIcon from "@mui/icons-material/LockReset";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import LockOpenIcon from "@mui/icons-material/LockOpen";
//import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import AnimatedPage from "../components/AnimatedPage";

function Profile() {
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")));
    const [setting, setSetting] = useState(JSON.parse(localStorage.getItem("setting")));
    const navigate = useNavigate();
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => localStorage.removeItem("user"));
        axios.get(`${process.env.REACT_APP_API_URL}/setting/get`, {}).then((res) => {
            if (setting != res.data.data[0]) {
                setSetting(res.data.data[0]);
                localStorage.setItem("setting", JSON.stringify(res.data.data[0]));
            }
        });
    }, []);
    return (
        <>
            <div className="main" style={{ paddingTop: "0.3rem" }}>
                {profile && (
                    <AnimatedPage>
                        <div className="account">
                            <div className="account__top promotionRule__container-content__rules-item">
                                <div className="promotionRule__container-content__rules-item__splitBorder">
                                    <span></span>
                                </div>
                                <div className="promotionRule__container-content__rules-item__borderTopStyle">
                                    <span></span>
                                    <span></span>
                                </div>
                                <div className="promotionRule__container-content__rules-item__titleLeft"></div>
                                <div className="promotionRule__container-content__rules-item__title">{profile.level === 0 ? "VIP 1" : "VIP " + profile.level}</div>
                                <div className="promotionRule__container-content__rules-item__titleRight"></div>
                                <div className="account__ID"></div>
                                <div className="account__balance">
                                    <div className="avatar">
                                        {profile ? (
                                            <span className="background-vip">
                                                <img
                                                    alt=""
                                                    src={require(`../../img/${profile.level === 0 ? "v1" : "v" + profile.level}.png`)}
                                                    className={`img-vip ${profile.level === 0 ? "img-vip-1" : "img-vip-" + profile.level}`}
                                                />
                                                <img alt="" src={require("../../img/profile-picture.jpg")} />
                                            </span>
                                        ) : null}
                                    </div>
                                    <div className="name">
                                        <h2>{profile.username}</h2>
                                        <strong id="account__balance">
                                            {Math.floor(profile.money).toLocaleString("vi-VN")} <small style={{ textTransform: "lowercase" }}>₫</small>
                                        </strong>
                                        <span>
                                            Mã giới thiệu: <b>{profile.code}</b>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="account__transaction">
                                <div className="account__transaction-box">
                                    <Link to="/recharge" className="account__transaction-item">
                                        <AddCardOutlinedIcon />
                                        <span>Nạp tiền</span>
                                    </Link>
                                    <div className="account__transaction-line"></div>
                                    <Link to="/withdraw" className="account__transaction-item">
                                        <PriceChangeOutlinedIcon />
                                        <span>Rút tiền</span>
                                    </Link>
                                </div>
                            </div>
                            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "20px", padding: "30px 20px 0" }}>
                                <Link to="/notification">
                                    <img src={require("../../images/btnkhuyenmai.png")} />
                                </Link>
                                <a href={setting?.down_link} target="_blank">
                                    <img src={require("../../images/btntaiapp.png")} />
                                </a>
                            </div>
                            <div className="account__menu">
                                <Link to="/detail" className="account__menu-item">
                                    <span>
                                        <PermIdentityIcon sx={{ fontSize: "25px" }} />
                                        Chi tiết tài khoản
                                    </span>
                                    <KeyboardArrowRightOutlinedIcon />
                                </Link>
                                <Link to="/transfergame" className="account__menu-item">
                                    <span>
                                        <DisplaySettingsIcon sx={{ fontSize: "25px" }} />
                                        Chuyển tiền vào sảnh
                                    </span>
                                    <KeyboardArrowRightOutlinedIcon />
                                </Link>
                                <Link to="/historyplay" className="account__menu-item">
                                    <span>
                                        <QueryStatsOutlinedIcon sx={{ fontSize: "25px" }} />
                                        Lịch sử tham gia
                                    </span>
                                    <KeyboardArrowRightOutlinedIcon />
                                </Link>
                                {/*<Link to="/history" className="account__menu-item">
                                    <span>
                                        <LocalAtmOutlinedIcon sx={{ fontSize: "25px" }} />
                                        Biến động số dư
                                    </span>
                                    <KeyboardArrowRightOutlinedIcon />
                                </Link>
                                <Link to="/money" className="account__menu-item">
                                    <span>
                                        <SavingsOutlinedIcon sx={{ fontSize: "25px" }} />
                                        Két tiết kiệm
                                    </span>
                                    <KeyboardArrowRightOutlinedIcon />
                                </Link>*/}
                                <Link to="/recharge_record" className="account__menu-item">
                                    <span>
                                        <CreditScoreOutlinedIcon sx={{ fontSize: "25px" }} />
                                        Lịch sử nạp
                                    </span>
                                    <KeyboardArrowRightOutlinedIcon />
                                </Link>
                                <Link to="/withdraw_record" className="account__menu-item">
                                    <span>
                                        <PaymentsOutlinedIcon sx={{ fontSize: "25px" }} />
                                        Lịch sử rút
                                    </span>
                                    <KeyboardArrowRightOutlinedIcon />
                                </Link>
                                <Link to="/addbank" className="account__menu-item">
                                    <span>
                                        <AccountBalanceOutlinedIcon sx={{ fontSize: "25px" }} />
                                        Liên kết ngân hàng
                                    </span>
                                    <KeyboardArrowRightOutlinedIcon />
                                </Link>
                                <Link to="/invite" className="account__menu-item">
                                    <span>
                                        <GroupOutlinedIcon sx={{ fontSize: "25px" }} />
                                        Mời bạn bè
                                    </span>
                                    <KeyboardArrowRightOutlinedIcon />
                                </Link>
                                {/*<Link to="/transfer" className="account__menu-item">
                                    <span>
                                        <MoveUpIcon sx={{ fontSize: "25px" }} />
                                        Chuyển tiền
                                    </span>
                                    <KeyboardArrowRightOutlinedIcon />
                                </Link>*/}
                                <Link to="/password" className="account__menu-item">
                                    <span>
                                        <LockPersonOutlinedIcon sx={{ fontSize: "25px" }} />
                                        Đổi mật khẩu đăng nhập
                                    </span>
                                    <KeyboardArrowRightOutlinedIcon />
                                </Link>
                                <Link to="/pass2" className="account__menu-item">
                                    <span>
                                        <LockOpenIcon sx={{ fontSize: "25px" }} />
                                        Đổi mật khẩu rút tiền
                                    </span>
                                    <KeyboardArrowRightOutlinedIcon />
                                </Link>
                                <div
                                    className="account__menu-item"
                                    onClick={() => {
                                        localStorage.removeItem("user");
                                        localStorage.removeItem("currentUser");
                                        localStorage.removeItem("profile");
                                        localStorage.removeItem("data");
                                        localStorage.removeItem("data1");
                                        var now = new Date();
                                        var time = now.getTime();
                                        var expireTime = time;
                                        now.setTime(expireTime);
                                        document.cookie = "jwt=;Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
                                        navigate("/login");
                                    }}>
                                    <span>
                                        <LogoutOutlinedIcon sx={{ fontSize: "25px" }} />
                                        Đăng xuất
                                    </span>
                                    <KeyboardArrowRightOutlinedIcon />
                                </div>
                            </div>
                        </div>
                    </AnimatedPage>
                )}
            </div>
            <Footer profile={profile} />
        </>
    );
}
export default Profile;
